export default {
  titleWrapper: 'h-[32px] mt-[32px] mb-[12px] flex items-center bg-[#E6EEF3] px-[10px] rounded',
  titleText: 'font-inter text-[14px] text-[#585D69] uppercase',
  feelWrapper: 'flex items-center justify-center h-[56px] w-[67px] bg-[#F0F3F6] border border-[#B3CBDC] rounded-lg',
  feel: 'text-[24px]',
  detailsContainer: 'flex justify-end fixed top-0 bottom-0 left-0 right-0 bg-[#10182880] overflow-y-scroll z-10',
  detailsModal: 'min-h-screen h-fit w-[625px] bg-color-white',
  headerWrapper: 'sticky top-0 pt-[28px] pb-[12px] bg-color-white px-[18px] z-10',
  headerWrapperBackground: 'z-0',
  declineModalOverlay: 'flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 bg-[#10182880] z-20',
  declineModal: 'w-[629px] bg-color-white rounded-2xl',
  declineHeader: 'flex items-center justify-between gap-3 min-h-[80px] px-[20px] bg-[#E7ECF2] rounded-t-2xl',
  declineHeaderText: 'font-interSemibold text-[24px] text-[#101828]',
  closeIcon: 'flex items-center justify-center min-h-[24px] min-w-[24px] bg-color-white rounded-full cursor-pointer',
  declineContent: 'px-[20px] mt-[20px] mb-[32px]',
  declineText: 'font-interMedium text-[16px] text-[#404653] mb-[8px]',
  text: 'ml-[8px]',
  textarea: 'w-[100%] p-[10px] font-interMedium text-[16px] border border-[#CCDCE8] rounded-lg',
  declineButtons: 'flex justify-end gap-[8px] mt-[20px]',
  cancelBtn: 'bg-[#E7E8EA]',
  cancelBtnText: 'text-[#404653]',
  yesDeclineBtn: 'bg-[#F04438]',
  yesDeclineBtnText: 'text-color-white',
  exportDropdown: 'font-inter',
  exportButton: 'cursor-pointer',
  exportOptions: 'cursor-pointer ml-[-165px] w-[190px] shadow-[0_5px_40px_0_rgba(0,0,0,0.16)] z-10 px-[11px] py-[13px] rounded-lg bg-color-white',
  exportOption: 'text-color-text px-[13px] py-[11px] hover:bg-[#F0F3F6] flex items-center rounded-lg',
  exportOptionTitle: 'text-[16px] ml-[12px] font-inter',
  entryWrapper: 'bg-color-white px-[18px] pb-[20px]',
  sliderContainer: 'fixed top-0 right-0 bg-[#10182880] flex justify-center',
};
