export default {
  container: 'px-[8px] mb-[32px]',
  commentList: 'flex flex-col gap-[18px]',
  commentItem: 'flex items-start gap-[12px]',
  commentAvatar: 'w-[48px] h-[48px] object-cover rounded-full border border-[#E6EEF3] border-solid',
  commentContent: 'flex-1 flex flex-col gap-[4px]',
  commentMeta: 'flex items-center justify-between',
  commentUser: 'font-interSemibold text-[#888C94] capitalize',
  commentDate: 'text-[#888C94] font-inter text-[14px]',
  commentDescription: 'font-inter mb-[4px]',
  commentActions: 'flex items-start gap-[12px]',
  likeIcon: 'text-[#F79009] cursor-pointer',
  actionLink: 'font-interMedium text-[14px] cursor-pointer hover:underline text-color-blue',
  commentListReplies: 'ml-[56px]',
  commentOptionsIconContainer: 'h-full flex items-center',
  commentOptionsIcon: 'text-color-text-grey hover:brightness-80',
  commentOptions: 'bg-color-white rounded mt-[-32px] shadow-[0_5px_40px_0_rgba(0,0,0,0.08)] z-10 py-[4px] border border-color-bg border-solid',
  commentOptionItem: 'bg-color-white py-[4px] my-[2px] cursor-pointer hover:brightness-95 pl-[12px] pr-[24px]',
  emptyMessage: 'mb-[16px]',
  viewMoreLink: 'ml-[56px] font-interMedium text-[14px] cursor-pointer hover:underline text-color-blue max-w-fit',
};
