/* eslint-disable global-require */
const SurveyCategory = [
  {
    id: 1,
    title: 'Place',
    childs: [
      {
        id: 2,
        name: 'Boundary',
        icon: require('../assets/icons/category/Boundary.png'),
      },
      {
        id: 5,
        name: 'Camp',
        icon: require('../assets/icons/category/Camp.png'),
      },
      {
        id: 6,
        name: 'Community',
        icon: require('../assets/icons/category/Community.png'),
      },
      {
        id: 7,
        name: 'House',
        icon: require('../assets/icons/category/House.png'),
      },
      {
        id: 8,
        name: 'Path',
        icon: require('../assets/icons/category/Path.png'),
      },
      {
        id: 9,
        name: 'Factory',
        icon: require('../assets/icons/category/Factory.png'),
      },
      {
        id: 10,
        name: 'Road',
        icon: require('../assets/icons/category/Road.png'),
      },
      {
        id: 11,
        name: 'Construction',
        icon: require('../assets/icons/category/Construction.png'),
      },
      {
        id: 12,
        name: 'Oil and gas extraction',
        icon: require('../assets/icons/category/Oilandgasextraction.png'),
      },
      {
        id: 13,
        name: 'Energy',
        icon: require('../assets/icons/category/Energy.png'),
      },
    ],
  },
  {
    id: 14,
    title: 'Nature',
    childs: [
      {
        id: 15,
        name: 'Tree',
        icon: require('../assets/icons/category/Tree.png'),
      },
      {
        id: 16,
        name: 'Plant',
        icon: require('../assets/icons/category/Plant.png'),
      },
      {
        id: 17,
        name: 'Animal',
        icon: require('../assets/icons/category/Animal.png'),
      },
      {
        id: 18,
        name: 'River',
        icon: require('../assets/icons/category/River.png'),
      },
      {
        id: 19,
        name: 'Lake',
        icon: require('../assets/icons/category/Lake.png'),
      },
      {
        id: 21,
        name: 'Waterfall',
        icon: require('../assets/icons/category/Waterfall.png'),
      },
      {
        id: 22,
        name: 'Invasive species',
        icon: require('../assets/icons/category/Invasivespecies.png'),
      },
      {
        id: 23,
        name: 'Coral reef',
        icon: require('../assets/icons/category/Coralreef.png'),
      },
      {
        id: 24,
        name: 'Sea grass',
        icon: require('../assets/icons/category/Seagrass.png'),
      },
      {
        id: 25,
        name: 'Mangrove',
        icon: require('../assets/icons/category/Mangrove.png'),
      },
      {
        id: 26,
        name: 'Beautiful scenery',
        icon: require('../assets/icons/category/Beautifulscenery.png'),
      },
    ],
  },
  {
    id: 27,
    title: 'People',
    childs: [
      {
        id: 28,
        name: 'Fishing site',
        icon: require('../assets/icons/category/Fishingsite.png'),
      },
      {
        id: 29,
        name: 'Hunting site',
        icon: require('../assets/icons/category/Huntingsite.png'),
      },
      {
        id: 30,
        name: 'Gathering site',
        icon: require('../assets/icons/category/Gatheringsite.png'),
      },
      {
        id: 31,
        name: 'Farmland',
        icon: require('../assets/icons/category/Farmland.png'),
      },
      {
        id: 32,
        name: 'Aquaculture',
        icon: require('../assets/icons/category/Aquaculture.png'),
      },
      {
        id: 33,
        name: 'Medicine',
        icon: require('../assets/icons/category/Medicine.png'),
      },
      {
        id: 34,
        name: 'Sacred site ',
        icon: require('../assets/icons/category/Sacredsite.png'),
      },
      {
        id: 35,
        name: 'History',
        icon: require('../assets/icons/category/History.png'),
      },
      {
        id: 36,
        name: 'Employment',
        icon: require('../assets/icons/category/Employment.png'),
      },
      {
        id: 37,
        name: 'Tourism',
        icon: require('../assets/icons/category/Tourism.png'),
      },
      {
        id: 38,
        name: 'Artisanal Mining',
        icon: require('../assets/icons/category/ArtisanalMining.png'),
      },
      {
        id: 39,
        name: 'Commercial mining',
        icon: require('../assets/icons/category/CommercialMining.png'),
      },
      {
        id: 40,
        name: 'Poaching',
        icon: require('../assets/icons/category/Poaching.png'),
      },
      {
        id: 41,
        name: 'Deforestation',
        icon: require('../assets/icons/category/Deforestation.png'),
      },
      {
        id: 42,
        name: 'Conflict',
        icon: require('../assets/icons/category/Conflict.png'),
      },
    ],
  },
  {
    id: 43,
    title: 'Hazard',
    childs: [
      {
        id: 44,
        name: 'Threat',
        icon: require('../assets/icons/category/Threat.png'),
      },
      {
        id: 45,
        name: 'Fire',
        icon: require('../assets/icons/category/Fire.png'),
      },
      {
        id: 46,
        name: 'Pollution',
        icon: require('../assets/icons/category/Pollution.png'),
      },
      {
        id: 47,
        name: 'Volcano',
        icon: require('../assets/icons/category/Volcano.png'),
      },
      {
        id: 48,
        name: 'Earthquake',
        icon: require('../assets/icons/category/Earthquake.png'),
      },
      {
        id: 50,
        name: 'Landslide',
        icon: require('../assets/icons/category/Landslide.png'),
      },
      {
        id: 51,
        name: 'Erosion',
        icon: require('../assets/icons/category/Erosion.png'),
      },
      {
        id: 52,
        name: 'Drought',
        icon: require('../assets/icons/category/Drought.png'),
      },
      {
        id: 53,
        name: 'Flooding',
        icon: require('../assets/icons/category/Flooding.png'),
      },
      {
        id: 54,
        name: 'Vandalism',
        icon: require('../assets/icons/category/Vandalism.png'),
      },
    ],
  },
];

export default SurveyCategory;
