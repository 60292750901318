export default {
  container: 'mt-[16px] border border-[#CCDCE8] rounded min-w-[1024px]',
  table: 'table-auto w-[100%] border-collapse',
  tableHeadRow: 'h-[41px] border-b border-[#CCDCE8]',
  headingTitle: 'text-left text-[#888C94] font-interMedium font-[500] text-[14px] uppercase',
  tableItemRow: 'h-[56px] border-t border-[#CCDCE8]',
  itemTitle: 'pl-[20px] text-color-text font-interMedium text-[16px]',
  categoryWrapper: 'flex items-center gap-[9px]',
  categoryIcon: 'h-[32px]',
  categoryTitle: 'text-color-text font-inter text-[16px]',
  date: 'text-color-text font-inter text-[16px]',
  status: 'max-w-fit px-[12px] py-[4px] rounded-full uppercase font-interMedium text-[12px]',
  improvement: 'text-color-text font-inter text-[16px]',
  pending: 'bg-[#FFF3E2] text-[#F79009]',
  rejected: 'bg-[#FFEFEE] text-[#F04438]',
  approved: 'bg-[#E7F5EF] text-[#12B76A]',
  button: 'text-[#00518B] font-interMedium text-[14px] mr-[12px] lg:mr-[8px] pl-[20px] hover:opacity-80 transition duration-200',
  notFound: 'p-[20px] text-color-text font-inter text-[16px]',
};
