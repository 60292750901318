export default {
  container: 'absolute top-[45px] rounded-lg shadow-[0_4px_42px_rgba(0,5,40,0.16)] z-10 md:right-[2em]',
  notificationWrapper: 'flex p-[10px] rounded-lg hover:bg-[#F0F3F6] cursor-pointer mb-1',
  notification: 'flex flex-col items-center gap-[4px]',
  iconContainer: 'flex justify-center cursor-pointer',
  icon: 'mr-[12px] text-[white] w-[2.5em] h-[2.5em] bg-[#0A52A1] rounded-[50%] text-[16px] p-[8px]',
  itemWrapper: 'w-[338px] bg-color-white z-auto px-[16px] py-[12px] rounded-lg right-0 max-h-[75vh] overflow-y-auto',
  description: 'font-inter self-start text-[16px] text-[#585D69]',
  date: 'self-start text-[12px] text-[#9FA3A9]',
};
