export default {
  heroSection: 'py-[20px] flex flex-col gap-[50px] sm:py-[150px] lg:py-[120px] lg:flex-row lg:gap-[20px]',
  heroContent: 'lg:w-[50%]',
  heroTitle: 'leading-[44px] -tracking-[0.025px] text-[36px] font-interBold text-color-blue sm:leading-[68px] sm:text-[64px] max-w-[510px]',
  heroText: 'font-inter text-[16px] leading-[24px] text-color-text my-[28px] sm:text-[18px] sm:leading-[26px] max-w-[510px]',
  heroInfo: 'font-interSemibold text-[16px] text-color-text leading-[24px] sm:text-[18px] sm:leading-[26px]',
  heroButtons: 'flex flex-row gap-[12px] mt-[28px] justify-start items-center',
  heroImageWrapper: 'hidden sm:flex sm:align-center sm:justify-center lg:w-[50%]',
  heroImage: 'max-h-[450px]',
  backgroundTitle: 'font-interBold text-[36px] leading-[44px] text-color-blue mt-[50px] mb-[56px] lg:mt-[0] lg:text-center sm:text-[48px] sm:leading-[58px]',
  bgContentWrapper: 'flex flex-col items-center gap-[40px] lg:flex-row lg:justify-between lg:items-start lg:gap-[20px]',
  bgImage: 'max-h-[250px] max-w-[250px] sm:max-h-[375px] sm:max-w-[375px]',
  bgContent: 'max-w-[604px] mb-[50px] lg:mb-[200px]',
  bgInfo: 'font-inter text-[16px] text-color-text sm:text-[18px] sm:leading-[32px] mb-4',
  highlightText: 'text-[#EC6D25]',
  bgCards: 'flex flex-col gap-[10px]',
  appInfoWrapper: 'px-[5vw] py-[50px] lg:py-[0] lg:absolute lg:-bottom-[136px] lg:left-0 lg:right-0',
  appInfoTitle: 'font-interBold text-[32px] text-color-text leading-[39px] mb-[64px]',
  appInfoCards: 'flex flex-row flex-wrap justify-center gap-[50px] lg:flex-nowrap',
  contentWrapper: 'max-w-[1440px] mx-auto px-[5vw] pt-[80px] lg:pt-[200px]',
};
