export default {
  navbar: 'w-full max-w-[1440px] mx-auto px-[5vw] py-[22px] lg:flex lg:justify-between z-[9] fixed left-0 lg:static',
  darkBar: 'bg-[#05375A]',
  lightBar: 'lg:bg-color-bg bg-color-white',
  toggle: 'absolute right-[20px] top-[30px] py-2 px-2 cursor-pointers',
  logoWrapper: 'cursor-pointer w-fit relative sm:z-auto z-20',
  logo: 'h-[66px]',
  menuList: 'overflow-hidden lg:items-center',
  darkMenuList: 'bg-[#05375A]',
  lightMenuList: 'bg-color-bg',
  linkWrapper: 'my-2.5 lg:my-0 flex justify-items-center text-center',
  link: 'lg:w-[100%] mx-auto lg:mx-3 lg:mr-8 font-interSemibold px-4 py-4 border-b-2 hover:opacity-70',
  externalLink: 'lg:w-[100%] mx-auto lg:mx-3 lg:mr-8 font-interSemibold px-4 py-4 hover:opacity-70',
  darkLink: 'bg-[#05375A] text-color-white',
  lightLink: 'lg:bg-color-bg',
  buttonWrapper: 'lg:ml-10 lg:mt-0 mt-[7vh] lg:mb-0 mb-[3vh]',
};
