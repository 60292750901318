export default {
  container: 'mt-[80px] sm:pt-[80px] lg:pt-[0]',
  header: 'text-center',
  heading: 'text-[64px] leading-[68px] font-interBold text-color-blue mb-[16px]',
  desc: 'text-[18px] leading-[32px]',
  contentWrapper: 'mt-[48px] grid grid-cols-1 gap-[48px] sm:grid-cols-2',
  content: 'bg-[#05375A] rounded-lg px-[36px] py-[32px] flex flex-col sm:gap-[80px] gap-[30px] h-fit',
  title: 'text-[36px] leading-[44px] text-color-white mb-[8px] font-interSemibold',
  message: 'text-[18px] leading-[32px] text-color-white',
  info: 'flex gap-[22px]',
  infoWrapper: 'flex flex-col gap-[32px]',
  mail: 'text-[18px] leading-[32px] text-color-white sm:max-w-[225px] max-w-full',
  socialMedia: 'flex gap-[32px]',
  formWrapper: 'w-full flex flex-col gap-[20px] py-[30px] bg-[#fff] px-[30px] mb-[60px] rounded-lg sm:rounded-none sm:px-0 sm:mb-0 sm:bg-transparent',
  row: 'grid sm:grid-cols-2 grid-cols gap-4',
  col: 'w-full',
  label: 'text-[16px] leading-none text-[#888c94] mb-[8px] interMedium',
  input: 'w-full border border-[#CEDCEC] bg-color-bg !rounded-lg text-[16px] text-[#585D69] !p-[12px] hover:border-color-blue-alt',
  submitButton: '!bg-color-blue w-fit !px-[45px] !rounded-lg !py-[15px] hover:bg-[#05375A] !font-interSemibold !text-[16px]',
};
