export default {
  mainContainer: 'bg-color-bg w-[100%] min-h-[100vh]',
  container: 'max-w-[1440px] mx-auto',
  contentContainer: 'flex items-center justify-center sm:pt-[120px] pt-[200px] p-4',
  contentWrapper: 'w-full max-w-[450px] py-[42px] rounded-3xl bg-color-white p-4 sm:p-8',
  header: 'flex items-center gap-[21px] mb-4',
  title: 'font-interSemibold sm:text-[32px] text-[24px] text-[#101828]',
  info: 'font-inter text-base text-[#585D69] mb-8',
  button: 'mt-[28px] mb-[32px]',
  email: 'font-interSemibold text-[#101828]',
  textWrapper: 'flex gap-[8px]',
  text: 'font-inter text-base text-[#585D69]',
  sendAgain: 'font-interSemibold text-base text-[#00518B]',
  cursor: 'cursor-pointer',
  error: 'text-color-red mt-[28px]',
  otpContainer: 'flex justify-between gap-1 sm:gap-2',
  otpInput: 'w-1/6 py-2 sm:py-3 border border-[#CCDCE8] rounded-lg font-interMedium text-[18px] sm:text-[24px] text-center',
};
