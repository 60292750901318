export default {
  categoryContainer: 'bg-color-white sm:w-[680px] w-[95%] max-h-[90%] overflow-scroll rounded',
  header: 'flex items-center justify-between py-3 px-5 bg-[#E7ECF2] sticky top-0',
  heading: 'font-interSemibold text-[20px]',
  closeWrapper: 'flex items-center justify-center min-h-[24px] min-w-[24px] bg-color-white rounded-full cursor-pointer',
  categoryList: 'p-2 mb-1',
  listHeading: 'text-[#404653] mb-2 font-interSemibold',
  categoryItem: 'cursor-pointer w-[calc(50%-4px)] sm:w-[calc(33.33%-6px)] border py-3 px-2 gap-1 flex flex-col justify-center items-center text-center border-[#CEDCEC] text-[#70747E] rounded hover:bg-[#F0F3F6] duration-300',
  categoryIcon: 'h-[35px]',
};
