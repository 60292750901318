export default {
  section: 'mt-[60px] flex flex-col items-center justify-center',
  backWrapper: 'mb-5 flex items-center gap-3 w-[100%] max-w-[643px] cursor-pointer',
  backTitle: 'text-base font-normal text-[#101828]',
  itemCard: 'p-8 rounded-2xl border border-[#E7ECF2] w-[100%] max-w-[643px]',
  itemHeader: 'flex gap-8',
  logoWrapper: 'bg-[#F0F3F6] rounded-xl p-6 flex items-center justify-center',
  logo: 'max-h-[100px] max-w-[100px]',
  title: 'text-2xl font-semibold text-[#101828]',
  infoWrapper: 'flex justify-between items-center mt-[12px] md:mt-[24px]',
  seperator: 'border-[1px] border-[#E7E8EA] h-[44px] m-[4%]',
  info: 'flex flex-col gap-2',
  infoHeading: 'text-sm font-semibold text-[#888c94]',
  infoData: 'text-base font-medium text-color-text',
  description: 'my-8',
  contactHeading: 'rounded bg-[#f0f3f6] mb-3 p-[6px] font-semibold text-sm text-[#585d69]',
  website: 'flex items-center gap-2 max-w-fit text-base font-medium text-color-text',
  contactWrapper: 'flex flex-col gap-2',
  contactItem: 'flex gap-2 items-center',
  contactTitle: 'font-base text-sm text-[#888c94] font-normal',
  contactValue: 'font-base text-sm text-color-text font-normal',
};
