import sadIcon from '../assets/icons/sad.svg';
import happyIcon from '../assets/icons/happy.svg';
import neutralIcon from '../assets/icons/normal.svg';

export const sentimentIcon: any = {
  '🙁': sadIcon,
  '🙂': happyIcon,
  '😐': neutralIcon,
};

const sentimentName: any = {
  '🙁': 'Sad',
  '🙂': 'Happy',
  '😐': 'Neutral',
};

export default sentimentName;
