export default {
  section: 'p-5 flex flex-col justify-center sm:w-fit w-[100vw]',
  backWrapper: 'mb-8 flex items-center gap-3 cursor-pointer self-start',
  backTitle: 'text-base font-normal text-[#101828]',
  content: 'flex sm:flex-row flex-col gap-8',
  itemContainer: 'w-[100%]',
  itemHeader: 'flex justify-between',
  logoWrapper: 'bg-[#F0F3F6] rounded-xl p-6 flex items-center justify-center',
  logo: 'max-h-[100px] max-w-[100px]',
  title: 'text-2xl font-semibold text-[#101828]',
  infoWrapper: 'flex gap-4 sm:gap-8 items-center mt-[12px] md:mt-[24px] flex-wrap',
  separator: 'border-[1px] border-[#E7E8EA] h-[44px] sm:static hidden',
  info: 'flex flex-col gap:1 sm:gap-2 w-[calc(50%-8px)]',
  infoHeading: 'text-sm font-semibold text-[#888c94]',
  infoData: 'text-base font-medium text-color-text',
  description: 'my-8',
  contactHeading: 'rounded bg-[#f0f3f6] mb-3 p-[6px] font-semibold text-sm text-[#585d69]',
  website: 'flex items-center gap-2 max-w-fit text-base font-medium text-color-text',
  contactWrapper: 'flex flex-col gap-2',
  contactItem: 'flex gap-2 items-center',
  contactTitle: 'font-base text-sm text-[#888c94] font-normal',
  contactValue: 'font-base text-sm text-color-text font-normal',
  userContainer: 'flex flex-col min-w-[300px] p-5 rounded-lg border-[1px] border-[#E7E8EA]',
  userHeaderContainer: 'flex items-center flex flex-row justify-between pb-8',
  userHeader: 'font-semibold color-[#404653]',
  userItem: 'flex flex-row items-center',
  nameInitial: 'flex justify-center items-center bg-[#5486BD] text-color-white inline rounded-3xl mr-4 w-8 h-8',
  secondaryButton: 'bg-[#F0F3F7] px-4 w-auto',
  secondaryButtonText: 'text-[#0A52A1] w-max',
  userList: 'flex flex-col gap-8',
  deleteIcon: 'ml-auto cursor-pointer',
  modal: 'bg-[transparent] rounded-md',
  modalContent: 'ms:p-8 py-4 px-2 bg-[white] rounded-md max-w-[100%]',
  modalActions: 'flex pt-4 gap-4 align-left',
  multiSelect: 'sm:w-[360px] w-[100%]',
  orgContainer: 'mt-8',
  orgHeader: 'bg-[#F0F3F7] p-2 rounded-md my-4 text-[#585D69] font-semibold uppercase',
  orgContent: 'flex flex-row items-center border-[1px] border-[#E7E8EA] rounded-lg p-4',
  orgLogo: 'w-16',
  orgName: 'pl-8 text-[#0A52A1] font-semibold font-medium',
};
