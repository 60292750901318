export default {
  container: 'px-[20px] mt-[24px] mb-[150px]',
  title: 'md:hidden mb-[20px] font-interSemibold text-[24px] text-[#101828]',
  header: 'flex flex-wrap gap-4 justify-between',
  tabs: 'sm:w-auto w-full',
  wrapper: 'flex gap-4 flex-wrap',
  selectInput: 'h-[42px] min-w-[calc(50%-8px)] sm:min-w-[12em] w-max cursor-pointer',
  datePicker: 'w-[260px] flex items-center gap-[10px] h-[42px] px-[14px] border border-[#CCDCE8] rounded-lg font-interMedium text-[14px] text-[#585D69] hover:brightness-90',
  csv: 'h-[42px] px-[12px] flex items-center rounded-lg border border-[#CCDCE8] font-interMedium text-[14px] text-[#70747E] hover:brightness-90',
  surveyTable: 'md:max-w-[calc(100vw-276px)] overflow-x-auto bg-color-white',
  footer: 'w-[100%] flex flex-wrap gap-4 items-center justify-between mt-[24px]',
  dropdownWrapper: 'flex gap-[12px] items-center',
  show: 'font-interMedium text-[14px] text-color-text',
  dropdownLabel: 'flex items-center gap-[15px] h-[42px] px-[14px] border border-[#CCDCE8] rounded-lg font-interMedium text-[14px] text-[#585D69] cursor-pointer hover:border-color-blue-alt',
  dropdownItems: 'flex-col py-[8px] px-[14px] font-interMedium border border-[#CCDCE8] text-[14px] rounded-lg',
  dropdownItem: 'cursor-pointer p-[10px] rounded-lg',
  filterWrapper: 'mt-4 flex-none flex flex-col-reverse lg:flex-row flex-wrap',
  selectInputWrapper: 'flex-1 flex lg:flex-row flex-wrap gap-4',
  clear: 'flex items-center cursor-pointer hover:text-[#585D69] h-[42px] hover:bg-[#E7ECF2] px-3 rounded-lg w-fit mb-4',
  analyticsButton: 'h-[44px] px-[12px] !w-fit rounded-lg',
};
